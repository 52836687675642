import * as React from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import { DetailHeader, DetailStart, DetailVideos, DetailFaq } from '@/components/Support/index'
import {
  supportBeamVideo1,
  supportBeamVideo1_jp,
  supportBeamVideoPic
} from '@/images/support/index'
import { useIntl } from "react-intl"
import { useGTMInit} from '@/hooks/useGTM.js'

const Index = () => {
  const intl = useIntl()
  React.useEffect(()=>{
    useGTMInit('support','beam')
  },[])
  const videoList = [
    {
        img: supportBeamVideoPic,
        link: intl.locale=='ja'?supportBeamVideo1_jp:supportBeamVideo1,
        title:intl.formatMessage({ id: "support_beam_video_title1", defaultMessage: "How to Get Started with XREAL Beam" }) ,
        time:intl.locale=='ja'?"04:30":"04:22"
    }
]

  return (
    <Layout menuBlack={true}>
      <SEO page="Support" />
      <DetailHeader pro='beam' />
      <DetailStart manualsLink='/support/XREAL_Beam_MANUAL_CN&EN&JP&KR_pdf' videoList={videoList}/>
      {/* <DetailVideos list={videoList} /> */}
      <DetailFaq type='beam' />

    </Layout>
  )
}

export default Index